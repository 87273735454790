import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import Image from 'next/image';
import Link from "next/link";

import { activeNavItemIdAtom, isSiteMenuActiveAtom } from "@atoms/siteHeader";
import { ArrowRightSmall } from "@components/Icons";
import getImageBlurOptions from "@lib/getImageBlurOptions";

const SiteMenu = ({ items = [] }) => {
  const [isSiteMenuActive, setIsSiteMenuActive] = useAtom(isSiteMenuActiveAtom);
  const [activeNavItemId, setActiveNavItemId] = useAtom(activeNavItemIdAtom);

  const handleNavExit = () => {
    setIsSiteMenuActive(false);
    setActiveNavItemId(null);
  };

  const activeItem = items.find((i) => i.id === activeNavItemId);

  const activeItemChildren = items.filter(
    (i) => i.parent?.id === activeNavItemId
  );

  const _Item = () => {
    return (
            <div className={clsx("SiteMenu__wrapper max-w-screen-xl")}>
        <div className={clsx("flex flex-row large:px-5")}>
          <div
            className={clsx(
              "SiteMenu_navSection w-auto grid content-start pt-7.5",
              {
                "grid-cols-3":
                  activeItem.itemColumns == 3 || !activeItem.itemColumns,
                "grid-cols-4": activeItem.itemColumns == 4,
              }
            )}
          >
            {activeItem.overviewLink.url && (
              (<Link
                  href={
                    activeItem.overviewLink.url
                    ? activeItem.overviewLink.url
                    : undefined
                  }
                  onClick={handleNavExit}
                  className={clsx("group", {
                      "col-span-3":
                          activeItem.itemColumns == 3 || !activeItem.itemColumns,
                      "col-span-4": activeItem.itemColumns == 4,
                  })}>

                  <h2
                      className="text-purple-01 mb-5 group-hover:underline"
                      onClick={handleNavExit}
                  >
                      {activeItem.overviewLink.text}
                      <div
                          className={clsx(
                              "SiteMenu__featuredContentItemIcon",
                              "inline-block",
                              "ml-1.5",
                              "transition-transform group-hover:translate-x-1"
                          )}
                      >
                          <ArrowRightSmall />
                      </div>
                  </h2>

              </Link>)
            )}
            {activeItemChildren.map((item) => {
              //const href = item.element ? getHref(item.element) : item.url;

              //const target = !item.element ? "_blank" : null;

              //const isActive = href === router.asPath;

              const columnChildren = items.filter(
                (i) => i.parent?.id === item.id
              );

              return (
                <div
                  key={item.id}
                  className={clsx("SiteMenu__column pr-7.5", {
                    "col-span-2": item.sectionColumns == 2,
                    "col-span-3": item.sectionColumns == 3,
                  })}
                >
                  <h2 className="text-purple-01 mb-5 border-b border-purple-01">
                    {item.title}
                  </h2>
                  {columnChildren.length > 0 && (
                    <div
                      className={clsx(
                        "SiteMenu__ColumnContents grid content-start gap-3",
                        {
                          "grid-cols-2": item.sectionColumns == 2,
                          "grid-cols-3": item.sectionColumns == 3,
                        }
                      )}
                    >
                      {columnChildren.map((item) => {
                        //const href = item.element ? getHref(item.element) : item.url;

                        const target =
                          item.target.type === "url" ? "_blank" : null;

                        //const isActive = href === router.asPath;

                        const subChildren = items.filter(
                          (i) => i.parent?.id === item.id
                        );

                        return (
                          <div
                            className={clsx(
                              "SiteMenu__ColumnContents-item break-inside-avoid",
                              "pl-1.5 mb-4",
                              {
                                "col-span-2": item.sectionColumns == 2,
                                "col-span-3": item.sectionColumns == 3,
                                "row-span-4": subChildren.length > 0,
                                "mb-3": subChildren.length > 0,
                              }
                            )}
                            key={item.id}
                          >
                            <Link
                              href={!!item.target ? item.target.url : ""}
                              className={clsx(" group", "block relative")}
                              onClick={handleNavExit}
                              target={target}>

                              <h3 className="group-hover:underline">
                                  {item.title}
                              </h3>
                              <div className="text-xs">
                                  {item.itemDescription}
                              </div>

                          </Link>
                            {subChildren.length > 0 && (
                              <ul className="list-disc list-inside">
                                {subChildren.map((item) => {
                                  //const href = item.element ? getHref(item.element) : item.url;

                                  //const target = !item.element ? "_blank" : null;

                                  return (
                                    <li key={item.id} className="mb-1.5">
                                    <Link
                                      href={
                                        !!item.target ? item.target.url : ""
                                      }
                                        className={clsx(
                                            "SiteMenu__ColumnContents-subItem",

                                            "hover:underline text-xs"
                                        )}
                                        onClick={handleNavExit}>

                                        {item.title}

                                    </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="basis-128 grow-0 shrink-0 SiteMenu__featuredContent py-7.5 px-5 bg-white-02">
            <h2 className="text-purple-01 mb-3 border-b border-purple-01">
              Featured Content
            </h2>
            {activeItem.featuredContent.map((content) => {
              return (
                (<Link
                    href={content.url ? content.url : ""}
                    key={content.id}
                    className={clsx(
                        "SiteMenu__featuredContentItem group",
                        "p-3 mb-5 bg-white-01",
                        "flex"
                    )}
                    onClick={handleNavExit}>

                    <div className="w-90 flex-none">
                        <div
                            className={clsx(
                                "EntryThumbnail__imageWrapper",
                                "block"
                            )}
                        >
                            {content.imageSmall &&
                                !!content.imageSmall[0] &&
                                !!content.imageSmall[0].url && (
                                    <Image
                                        unoptimized={true}
                                        src={content.imageSmall[0].url}
                                        height={content.imageSmall[0].height}
                                        width={content.imageSmall[0].width}
                                        alt={content.imageSmall[0].title}
                                        layout="responsive"
                                        className={clsx("EntryThumbnail__image")}
                                        draggable={false}
                                        {...getImageBlurOptions(
                                            content.imageSmall[0],
                                            content.imageBlur[0]
                                        )}
                                    />
                                )}
                        </div>
                    </div>
                    <div className="w-auto pl-3 content-details flex-grow">
                        <span className="text-xs text-gray-01 uppercase">
                            {content.resourceTypes &&
                                content.resourceTypes[0].title}
                            {content.blogCategory && content.blogCategory[0].title}
                        </span>
                        <h3 className="text-sm">{content.title}</h3>
                        <div className="text-right text-gray-01 group-hover:text-black-01">
                            <span className="text-xs text-right group-hover:underline">
                                Learn More
                            </span>
                            <div
                                className={clsx(
                                    "SiteMenu__featuredContentItemIcon",
                                    "inline-block",
                                    "ml-3",
                                    "transition-transform group-hover:translate-x-1"
                                )}
                            >
                                <ArrowRightSmall />
                            </div>
                        </div>
                    </div>

                </Link>)
              );
            })}
          </div>
        </div>
      </div>
        );
  };

  return (
    <AnimatePresence>
      {isSiteMenuActive && (
        <motion.div
          className={clsx("SiteMenu__root", "fixed inset-0 z-40")}
          initial={{ opacity: 0, y: -30 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { ease: "easeOut" },
          }}
          exit={{ opacity: 0, y: -30, transition: { ease: "easeOut" } }}
        >
          <div
            className={clsx(
              "SiteMenu__content",
              "transition-all",
              "absolute z-10 inset-x-0 top-0 transform",
              "bg-white-01"
            )}
          >
            <div className={clsx("border-b relative")}>
              <div
                className={clsx("max-w-screen-xl mx-auto overflow-auto")}
                style={{ maxHeight: `calc(100vh)` }}
              >
                <_Item />
              </div>
              <div
                className={clsx(
                  "SiteMenu__shadowContainer absolute h-3 top-0 w-full",
                  "shadow-nav"
                )}
              ></div>
            </div>
          </div>
          <div
            className="SiteMenu__overlay fixed inset-0 z-09"
            onClick={handleNavExit}
          ></div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SiteMenu;
