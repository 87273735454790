import clsx from "clsx";

import Target from "@components/Target";
import { ArrowRight } from "./Icons";

const PrimaryButton = ({ target, isFull = false, outline = false }) => {
	return (
		<Target
			target={target}
			className={clsx(
				"PrimaryButton",
				"flex items-center justify-center",
				"h-10 px-5 py-5",
				"rounded-sm",
				"transition-colors",
				{
					"bg-purple-01 hover:bg-black-01 text-white-01": !outline,
					"border border-purple-01 hover:bg-purple-01 text-purple-01 hover:text-white-01":
						outline,
				},
				{
					"w-full": isFull,
				}
			)}
		>
			<div className={clsx("PrimaryButton__label", "whitespace-nowrap")}>
				{target.text}
			</div>
			<div
				className={clsx(
					"EmbeddedForm__buttonIcon",
					"ml-4",
					"transition-transform group-hover:translate-x-1"
				)}
			>
				<ArrowRight />
			</div>
		</Target>
	);
};

export default PrimaryButton;
